import React, { useState } from "react";
import { Menu, Dropdown } from "antd";
import "./mobile-menu.scss"; // Import your CSS file
import { MenuIcon } from "../../img";

const MobileMenu = ({ scrollTab }) => {
  const [visible, setVisible] = useState(false);

  const handleMenuClick = (e) => {
    console.log(`Clicked on ${e.key}`);
    setVisible(false);
  };

  const menuItems = [
    {
      key: "about",
      value: "About",
    },
    {
      key: "services",
      value: "Services",
    },
    {
      key: "reviews",
      value: "Reviews",
    },
    {
      key: "contact",
      value: "Contact",
    },
  ];

  const menu = (
    <Menu onClick={handleMenuClick} className="glass-menu">
      {menuItems.map((el, index) => (
        <Menu.Item onClick={() => scrollTab(index)} key={el?.key}>
          {el?.value}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="dropdown-menu-mobile">
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        visible={visible}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onVisibleChange={(flag) => setVisible(flag)}
      >
        <img
          onClick={() => setVisible(!visible)}
          style={{ width: "65px" }}
          src={MenuIcon}
          alt="menu"
        />
      </Dropdown>
    </div>
  );
};

export default MobileMenu;
