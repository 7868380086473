import React, { useState } from "react";
import "./footer.scss";
import { SocialIcons, VectorLogo } from "../img";
import { Divider, Row, Col } from "antd";
import DemoForm from "../components/demo-form/demo-form";

const Footer = ({ scrollTab, contactRef }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Row className="footer-wrapper">
      <div ref={contactRef} className="footer-container">
        <div className="header-div">
          <div className="left-content" style={{ flexDirection: "column" }}>
            <div className="logo-and-text" style={{ width: "200px" }}>
              <div className="demo-logo">
                <img src={VectorLogo} />
              </div>
              <div className="logo-with-text">
                <div className="logo-payzeasy">PayzEasy</div>
                <div className="small-text">Simplify Payments With Ease</div>
              </div>
            </div>
            <div className="abouts">
              PayzEasy Payzcart helps you to develop your organization or a
              business to accept payment seamlessly digitally.
            </div>
          </div>
        </div>
        <div className="right-content">
          <div className="links-container">
            <div className="bold-heading">Useful Links</div>
            <div>
              <div onClick={() => window.scrollTo(0,0)} className="links">Home</div>
              <div onClick={() => scrollTab(0)} className="links">About</div>
              <div onClick={() => scrollTab(1)} className="links">Services</div>
              <div onClick={() => scrollTab(2)} className="links">Reviews</div>
              <div onClick={() => setIsModalVisible(true)} className="links">Contact</div>
            </div>
          </div>
          <div className="links-container">
            <div className="bold-heading">Contact</div>
            <div className="links">Email: info@payzeasy.in</div>
          </div>
        </div>
      </div>
      <Divider
        style={{
          margin: 0,
          padding: "10px 0",
          borderTop: "1px solid rgb(85 81 81 / 53%)",
        }}
      />
      <Col className="footer-strip">
        <div
          style={{ color: "#fff", fontFamily: "Clash Display Light" }}
          className="copyright"
        >
          @2023{" "}
          <span style={{ color: "#48B7EF", fontFamily: "Clash Display" }}>
            PayzEasy
          </span>{" "}
          All Rights Reserved
        </div>
        <div className="social-icons">
          <img src={SocialIcons} />
        </div>
        <div style={{ padding: 0 }} className="bold-heading follow">
          Follow Us
        </div>
      </Col>
      {isModalVisible && <DemoForm visible={isModalVisible} onCancel={handleCancel} />}
    </Row>
  );
};

export default Footer;
