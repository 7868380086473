import "./App.scss";
import { Layout } from "antd";
import HeaderComp from "./header/header-comp";
import Home from "./components/home/home";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

const { Content } = Layout;

function App() {
  return (
    <Layout style={{ height: "100%" }}>
      {/* <HeaderComp /> */}
        <div>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Outlet />}>
                <Route path="/" index element={<Home />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
    </Layout>
  );
}

export default App;
