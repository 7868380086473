import React from "react";
import { Menu, Layout, Button, Row, Col } from "antd";
import "./header.scss";
import { VectorLogo } from "../img";
import MobileMenu from "../components/home/mobile-menu";
const { Header } = Layout;

const HeaderComp = ({ scrollTab }) => {
  const menuItems = [
    {
      key: "about",
      value: "About",
    },
    {
      key: "services",
      value: "Services",
    },
    {
      key: "reviews",
      value: "Reviews",
    },
    {
      key: "contact",
      value: "Contact",
    },
  ];

  return (
    <Header className="header-div">
      <Row>
        <Col span={24} className="left-content">
          <Col md={{ span: 9 }} className="logo-and-text">
            <div className="demo-logo">
              <img src={VectorLogo} />
            </div>
            <div className="logo-with-text">
              <div className="logo-payzeasy">PayzEasy</div>
              <div className="small-text">Simplify Payments With Ease</div>
            </div>
          </Col>
          <Col sm={{ span: 0 }} xs={{ span: 0 }} md={{ span: 18 }}>
            <Menu mode="horizontal">
              {menuItems.map((el, index) => (
                <Menu.Item onClick={() => scrollTab(index)} key={el?.key}>
                  {el?.value}
                </Menu.Item>
              ))}
            </Menu>
          </Col>
          <Col className="mobile-menu" sm={{ span: 9 }}>
            <MobileMenu scrollTab={scrollTab} />
          </Col>
        </Col>
      </Row>
      <Col
        xs={{ span: 0 }}
        lg={{ span: 2 }}
        md={{ span: 2 }}
        className="right-content"
      >
        <Button style={{ background: "#48B7EF" }}>Get Started</Button>
      </Col>
    </Header>
  );
};

export default HeaderComp;
