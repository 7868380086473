import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import CryptoJS from "crypto-js";
import axios from "axios";

const IV = process.env.REACT_APP_IV_KEY;
const key = process.env.REACT_APP_KEY;

var iv = CryptoJS?.enc?.Latin1?.parse(IV);
var Cryptokey = CryptoJS?.enc?.Latin1?.parse(key);


const encryptData = (data) => {
  console.log(data, "data");
  const encrypted = CryptoJS?.AES?.encrypt(data, Cryptokey, {
    iv: iv,
    mode: CryptoJS?.mode?.CBC,
    padding: CryptoJS?.pad?.ZeroPadding,
  });
  return encrypted.toString();
};

const DemoForm = ({ visible, onCancel }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const { emailTo, subject, body, referralCode } = values;

      const encryptedBody = encryptData(`payzeasy#9876~${emailTo}`);
      console.log(encryptedBody, "body");

      const response = await axios.post(
        process.env.REACT_APP_ENDPOINT+"/api/v1/bkoffice/notification/sendEmail",
        {
          emailTo,
          subject,
          body: body,
          referralCode,
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            iv: encryptedBody,
          },
        }
      );

      if (response.status === 200) {
        message.success("Email sent successfully");
      } else {
        message.error("Error sending email");
      }

      onCancel();
    } catch (error) {
      console.error("Error:", error.message);
      message.error("Something went wrong");
    }
  };

  return (
    <Modal
      visible={visible}
      title="CONTACT US"
      centered
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="emailTo"
          label="Email To"
          rules={[
            { required: true, message: "Please enter the email address" },
          ]}
        >
          <Input placeholder="Email To" />
        </Form.Item>

        <Form.Item
          name="subject"
          label="Subject"
          rules={[{ required: true, message: "Please enter the subject" }]}
        >
          <Input placeholder="Subject" />
        </Form.Item>

        <Form.Item
          name="body"
          label="Body"
          rules={[{ required: true, message: "Please enter the body" }]}
        >
          <Input.TextArea placeholder="Write you queries here ..." rows={5} style={{ resize: "none" }} />
        </Form.Item>

        <Form.Item name="referralCode" label="Referral Code">
          <Input placeholder="Referral Code" />
        </Form.Item>

        <div className="submit-btn">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DemoForm;
